<script setup lang="ts">
import type { WebSite } from 'schema-dts'

const { data, status, error } = await useFetch('/internals/homepage', {
  method: 'GET',
  lazy: false,
})

if (error.value) {
  throw createError({ ...error.value, fatal: true })
}

const { isLoading } = useIsLoading(status)

const metaTitle = computed(() => useHeadTitle(data.value?.seo_title))
const metaImage = useSeoImageURL(null, { width: META_IMAGE_WIDTH, height: META_IMAGE_HEIGHT })
const requestURL = useRequestURL()
const { alternateLocales } = useAlternateLocale()
const { topLevelLocale: locale } = useTopLevelLocale()
const { format } = getOpenGraphLocaleFormatter()

useSeoMeta({
  title: metaTitle,
  ogTitle: metaTitle,
  twitterTitle: metaTitle,
  description: data.value?.description,
  ogDescription: data.value?.description,
  twitterDescription: data.value?.description,
  ogImage: metaImage,
  ogImageWidth: META_IMAGE_WIDTH,
  ogImageHeight: META_IMAGE_HEIGHT,
  twitterImage: metaImage,
  ogUrl: requestURL.href,
  ogLocaleAlternate: alternateLocales.value.map(({ code }) => format(code)),
})

const url = useRentalBaseURL()

useHead({
  link: [
    { rel: 'canonical', href: requestURL.href },
    ...alternateLocales.value.map(({ domain, code }) => {
      const { href } = new URL(`${requestURL.protocol}//${domain}`)
      return { rel: 'alternate', hreflang: getTopLevelLocale(code), href }
    }),
    { rel: 'alternate', hreflang: getTopLevelLocale(locale.value), href: requestURL.href },
    { rel: 'alternate', hreflang: 'x-default', href: 'https://blog.yescapa.com' },
  ],
  script: [
    getLdJsonScript<WebSite>({
      '@type': 'WebSite',
      'name': 'Yescapa Blog',
      'url': requestURL.href,
      'description': data.value?.description,
      'datePublished': data.value?.first_published_at,
      'dateModified': data.value?.last_modified_at,
      'publisher': {
        '@type': 'Organization',
        'name': 'Yescapa',
        'url': url.href,
        'logo': {
          '@type': 'ImageObject',
          'url': new URL('icon.png', url).href,
          'width': '1024',
          'height': '1024',
        },
      },
    }),
  ],
})
</script>

<template>
  <div>
    <LazyAppPageLoading v-if="isLoading" />
    <template v-else-if="data">
      <AppHomeHeaderPage
        v-if="data.featured_image.url"
        :image="data.featured_image.url"
        :image-alt="data.featured_article.title"
      >
        <template
          v-if="data.featured_article.tag"
          #suptitle
        >
          {{ data.featured_article.tag }}
        </template>

        <template #title>
          <NuxtLink
            :to="data.featured_article.html_path"
            class="block focus-visible:z-10 focus-visible:rounded focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
          >
            {{ data.featured_article.title }}
            <span
              class="absolute inset-0"
              aria-hidden="true"
            />
          </NuxtLink>
        </template>

        <template #subtitle>
          <p v-if="data.featured_article.sub_title">
            {{ data.featured_article.sub_title }}
          </p>
          <!-- eslint-disable vue/no-v-html -->
          <div
            v-else
            v-html="data.featured_article.description"
          />
          <!-- eslint-enable vue/no-v-html -->
        </template>
      </AppHomeHeaderPage>

      <AppPostBlock
        v-for="(block, index) in data.body"
        :id="block.id"
        :key="block.id"
        :type="block.type"
        :content="block.value"
        :reverse="index % 2 !== 0"
      />
    </template>
  </div>
</template>
