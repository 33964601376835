<script setup lang="ts">
const props = defineProps<{ image: string, imageAlt?: string }>()

const ratio = 16 / 8

const { getTwicpicsCoverHref, getFixedWidthsSet, getResponsiveWidthsSet, toSrcsetString } = useLCP({ imagePath: props.image, ratio })

// fixed rule "1248px"
const fixedWidths = getFixedWidthsSet(1248)
const fixedSrcSet = toSrcsetString(fixedWidths)

// responsive rule "(max-width: 1248px) 100vw"
const responsiveWidths = getResponsiveWidthsSet(1248)
const maxWidth = Math.max(...responsiveWidths)
const responsiveSrcSet = toSrcsetString(new Set<number>([...responsiveWidths, ...[...fixedWidths].filter(w => w < maxWidth)]))

const unionSrcSet = toSrcsetString(new Set<number>([...responsiveWidths, ...fixedWidths]))

const responsiveHref = getTwicpicsCoverHref({ width: 320 })
const fixedHref = getTwicpicsCoverHref({ width: 1248 })

useHead({
  link: [
    {
      rel: 'preload',
      as: 'image',
      media: '(max-width: 1248px)',
      href: responsiveHref,
      imagesrcset: responsiveSrcSet,
      imagesizes: '100vw',
      fetchpriority: 'high',
    },
    {
      rel: 'preload',
      as: 'image',
      media: '(min-width: 1249px)',
      href: fixedHref,
      imagesrcset: fixedSrcSet,
      imagesizes: '1248px',
      fetchpriority: 'high',
    },
  ],
})
</script>

<template>
  <div class="relative sm:container">
    <img
      :src="responsiveHref"
      :srcset="unionSrcSet"
      :sizes="`
        (max-width: 1248px) 100vw,
        1248px
      `"
      fetchpriority="high"
      :alt="props.imageAlt"
      :style="`aspect-ratio: ${ratio};`"
      class="object-cover w-full sm:rounded-b"
    >
    <div class="w-full space-y-3 bg-white p-4 lg:absolute lg:bottom-0 lg:max-w-xl lg:rounded-tr lg:p-8">
      <div
        v-if="$slots.suptitle"
        class="text-xs font-semibold uppercase text-pink-500"
      >
        <slot name="suptitle" />
      </div>
      <h1
        v-if="$slots.title"
        class="text-xl font-semibold md:text-2xl lg:text-4xl"
      >
        <slot name="title" />
      </h1>
      <div
        v-if="$slots.subtitle"
        class="text-lg line-clamp-4"
      >
        <slot name="subtitle" />
      </div>
    </div>
  </div>
</template>
